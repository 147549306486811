<template>
    <v-app style="background-color: #fafafa">
        <showactionresult :message="messages"></showactionresult>
        <v-app-bar app
                   clipped-left
                   tile
                   v-bind:height="isMobile ? 48 : 60"
                   color="white"
                   elevation="1">
            <v-app-bar-nav-icon @click="drawer = !drawer" class="primary--text"></v-app-bar-nav-icon>
            <v-row class="">
                <v-col cols="auto" class="d-flex align-center ml-4">
                    <router-link to="/Account/Login">
                        <img v-bind:height="20" src="@/assets/logo.png" alt="Logo" />
                    </router-link>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex align-center">
                    <span class="d-flex align-center font-weight-bold primary--text caption" style="cursor:pointer" @click="OpenHelpDocument()">
                        <v-icon small left>mdi-lifebuoy</v-icon> {{ $t("UserGuide") }}
                    </span>
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                    <span class="d-flex align-center font-weight-bold primary--text caption" style="cursor:pointer" @click="ChangeLanguage()">
                        <v-icon small left>mdi-earth</v-icon> {{ $t("ChangeLanguageText") }}
                    </span>
                </v-col>
                <v-col cols="auto">
                    <v-divider vertical></v-divider>
                </v-col>
                <v-col cols="auto">
                    <span class="font-weight-medium title">{{ $t("SupplierPortal") }}</span>
                </v-col>
            </v-row>
        </v-app-bar>
        <v-navigation-drawer app clipped mobile-breakpoint="sm" disable-resize-watcher disable-route-watcher v-model="drawer" color="primary" width="280">
            <v-list nav>
                <v-list-item-group active-class="white--text" v-model="menuItemsSelected">
                    <v-list-item v-for="(item, i) in items" :key="i" :to="item.url" class="mb-1" active>
                        <v-list-item-icon>
                            <v-icon class="white--text">{{item.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-tooltip right v-bind:disabled="!isEllipsisActive(`menu-item-${item.text}`)" color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title v-bind:id="`menu-item-${item.text}`" class="white--text" style="white-space:normal" v-bind="attrs" v-on="on">
                                        {{$t(item.text)}}
                                    </v-list-item-title>
                                </template>
                                <span>
                                    {{$t(item.text)}}
                                </span>
                            </v-tooltip>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <br />
        <router-view />
    </v-app>
</template>

<script>
    import Vue from "vue"
    import Vuex from "vuex"
    import { FormMixIn } from "@/MixIns/FormMixins";

    export default {
        mixins: [FormMixIn],
        data: function ()
        {
            return {
                drawer: true,
                menuItemsSelected: null,
                items: [],
                validateError: '',
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
            };
        },
        created: function ()
        {
            this.drawer = !this.isMobile;

            this.items = [
                { text: 'Login', icon: 'mdi-login-variant', url: '/Account/Login' },
                { text: 'CreateAccount', icon: 'mdi-account-plus-outline', url: '/Account/Register' },
                { text: 'ForgotPassword', icon: 'mdi-account-question-outline', url: '/Account/Forgot' },
                { text: 'BecomeASupplier', icon: 'mdi-account-edit-outline', url: '/Account/Application' },
            ];
        }
    };
</script>