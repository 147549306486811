var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "background-color": "#fafafa" } },
    [
      _c("showactionresult", { attrs: { message: _vm.messages } }),
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            "clipped-left": "",
            tile: "",
            height: _vm.isMobile ? 48 : 60,
            color: "white",
            elevation: "1",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "primary--text",
            on: {
              click: function ($event) {
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c(
            "v-row",
            {},
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center ml-4",
                  attrs: { cols: "auto" },
                },
                [
                  _c("router-link", { attrs: { to: "/Account/Login" } }, [
                    _c("img", {
                      attrs: {
                        height: 20,
                        src: require("@/assets/logo.png"),
                        alt: "Logo",
                      },
                    }),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-flex align-center font-weight-bold primary--text caption",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.OpenHelpDocument()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v("mdi-lifebuoy"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("UserGuide")) + " "),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-flex align-center font-weight-bold primary--text caption",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.ChangeLanguage()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v("mdi-earth"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("ChangeLanguageText")) + " "),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [_c("v-divider", { attrs: { vertical: "" } })],
                1
              ),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("span", { staticClass: "font-weight-medium title" }, [
                  _vm._v(_vm._s(_vm.$t("SupplierPortal"))),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            app: "",
            clipped: "",
            "mobile-breakpoint": "sm",
            "disable-resize-watcher": "",
            "disable-route-watcher": "",
            color: "primary",
            width: "280",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { "active-class": "white--text" },
                  model: {
                    value: _vm.menuItemsSelected,
                    callback: function ($$v) {
                      _vm.menuItemsSelected = $$v
                    },
                    expression: "menuItemsSelected",
                  },
                },
                _vm._l(_vm.items, function (item, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      staticClass: "mb-1",
                      attrs: { to: item.url, active: "" },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { staticClass: "white--text" }, [
                            _vm._v(_vm._s(item.icon)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                right: "",
                                disabled: !_vm.isEllipsisActive(
                                  `menu-item-${item.text}`
                                ),
                                color: "primary",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-list-item-title",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "white--text",
                                                staticStyle: {
                                                  "white-space": "normal",
                                                },
                                                attrs: {
                                                  id: `menu-item-${item.text}`,
                                                },
                                              },
                                              "v-list-item-title",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t(item.text)) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t(item.text)) + " "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }